.task-item-container{
    margin: 0 auto;
    max-width: 1520px;
    padding: 20px 0 0 0;
}
.parent-block{
    display: flex;
    justify-content: center;
    gap: 20px;
    height: 800px;
}
.task-item-container .parent-block .block {
    padding: 10px;
}
.task-item-container .parent-block .first {
    width: 40%;
    border-radius: 20px;
}
.task-item-container .parent-block .second {
    width: 60%;
    border-radius: 20px;
    position: relative;
}

.task-item-container .btn:hover {
    text-decoration: none;
    background: #2c699c;
}
.task-item-container .send {
    width: 130px;
}
.task-item-container .return {
    width: 210px;
    margin-right: 10px;
}

.task-item-container .block{
    background: #fff;
    color: #4a4a4a;
    margin-bottom: 10px;
    padding: 40px;
}
.task-item-container .block-child-first-tab {
    display: flex;
    flex-direction: column;
    padding: 20px;
    height: 83vh;
}
.block_chip{
    padding-top: 5px;
    margin-top: auto;
}
.collapsed_text__accepted{
    color: #0de30d;
    border-left: 1px solid #0de30d;
}
.collapsed_text__error{
    color: red;
    border-left: 1px solid red;
}
.collapsed_child_text__accepted{
    color: #0de30d;
}
.collapsed_child_text__error{
    color: red;
}
.collapsed_text{
    display: flex;
    cursor: pointer;
    margin-top: 10px;
    height: 20px;
}
.accepted {
    color:#0de30d;
}
.not-accepted{
    color: #f14536;
}
.result-test{
    font-weight: bold;
}
.test-case-time{
    font-size: smaller;
}
.test-case-count{
    font-weight: bold;
}

.block-child-first-tab__description{
    max-height: 720px;
    overflow: auto;
}
.title{
    font-weight: bold !important;
}
.block-child-first-tab__description{
    overflow-y: auto;
}
.block-child-first-tab__description::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.block-child-first-tab__description::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px grey;
    border-radius: 10px;
}

/* Handle */
.block-child-first-tab__description::-webkit-scrollbar-thumb {
    background: red;
    border-radius: 10px;
}