#root {
    display: flex;
    flex-direction: column;
}

.main-content{
    margin: 0 auto;
    max-width: 1200px;
    min-width: auto;
    /*padding: 0 32px 0;*/
    position: relative;
}

/*@media (max-width: 1200px) {*/
/*    .main-content {*/
/*        width: 500px;*/
/*    }*/
/*}*/

/*@media (max-width: 992px) {*/
/*    .main-content {*/
/*        width: 800px;*/
/*    }*/
/*}*/

/*@media (max-width: 768px) {*/
/*    .main-content {*/
/*        width: 700px;*/
/*    }*/
/*}*/

/*@media (max-width: 576px) {*/
/*    .main-content {*/
/*        width: 500px;*/
/*    }*/
/*}*/

.task-expired__header{
    align-items: center;
    background-color: #fff;
    display: flex;
    font-family: Roboto-Black,sans-serif;
    font-size: 20px;
    justify-content: center;
    padding: 20px 0;
    margin-bottom: 20px;
    position: relative;
}
.login_btn {
    position: absolute;
    right: 5px
}