.block-completed {
    background-color: #FFFFFF;
    border-radius: 20px;
    padding: 40px;
    display: flex;
    flex-direction: column;
}
.left-side__title {
    font-weight: 500 !important;
    font-size: 30px;
    line-height: 34px;
    color: #1F1F1F;
    margin: 0 0 10px 0;
}
.left-side__label {
    margin: 0;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 20px;
    color: #8F8F8F;
}
.left-side__check {
    margin: 0 0 24px 0;
    font-weight: 400 !important;
    font-size: 20px;
    line-height: 24px;
    color: #1F1F1F;
}
.block-completed__top {
    display: flex;
    align-items: center;
}
.right-side__toggle-btn{
    width: 40px;
    height: 40px;
    background-color: #F2F2F2;
    border-radius: 100px;
}
.block-completed {
    margin-bottom: 24px;
}
.block-completed:last-child {
    margin: 0;
}
.success-block {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}
.success-block__points {
    border-radius: 8px;
    padding: 10px;
    background-color: #FFD9D9;
    width: max-content;
}
.success-block__comment-block {
    background-color: #F2F2F2;
    border-radius: 16px;
    padding: 10px 24px 10px 24px;
}